import * as React from 'react';
import styled from '@emotion/styled';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#04bebe',
    },
    secondary: {
      main: '#0461be',
    },
  },
});

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <MuiThemeProvider theme={theme}>
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </MuiThemeProvider>
);

export default LayoutRoot;
