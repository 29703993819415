import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import SideDrawer from './Drawer';
import Header from '../components/Header';
import LayoutRoot from '../components/LayoutRoot';
import LayoutMain from '../components/LayoutMain';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { colors } from '../styles/variables';

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: colors.background,
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
  }),
);

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const IndexLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: data.site.siteMetadata.keywords },
            ]}
          />
          <Header title={data.site.siteMetadata.title} handleDrawerToggle={handleDrawerToggle} />
          <SideDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            title={data.site.siteMetadata.title}></SideDrawer>
          <LayoutMain className={classes.content}>{children}</LayoutMain>
        </LayoutRoot>
      )}
    />
  );
};

export default IndexLayout;
export { default as PrivateRoute } from '../components/private/Protected';
