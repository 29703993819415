import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
import { heights, dimensions } from '../styles/variables';
import Container from './Container';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { colors } from '../styles/variables';
import { RootState } from '../store';
import { checkIsClient } from '../utils';

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.background};
`;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: right;
  height: 100%;
  margin-right: 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconBtn: {
      justifyContent: 'right',
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      justifyContent: 'left',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

interface HeaderProps {
  title: string;
  handleDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerToggle }) => {
  const classes = useStyles();

  if (!checkIsClient()) return <div />;

  const firebase = useFirebase();
  const currentUser = firebase.auth().currentUser;
  const auth = useSelector((state: RootState) => state.firebase.auth);

  const handleLogOut = async () => {
    await firebase.auth().signOut();
    if (checkIsClient()) navigate('/');
  };

  let signoutButton;
  if (isLoaded(auth) && !isEmpty(auth)) {
    signoutButton = (
      <Tooltip title="Log Out">
        <IconButton color="secondary" className={classes.iconBtn} onClick={handleLogOut}>
          <ExitToAppIcon></ExitToAppIcon>
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <StyledHeader>
      <HeaderInner>
        <div>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
        </div>
        <div className={classes.title}></div>
        <div>
          {currentUser && <Chip color="secondary" avatar={<Avatar />} label={currentUser.email} />}
          {signoutButton}
        </div>
      </HeaderInner>
    </StyledHeader>
  );
};

export default Header;
