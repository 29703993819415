import * as React from 'react';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { colors } from '../styles/variables';
import styled from '@emotion/styled';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarIcon from '@material-ui/icons/Star';
import PeopleIcon from '@material-ui/icons/People';
import PieChartIcon from '@material-ui/icons/PieChart';
import FacebookIcon from '@material-ui/icons/Facebook';
import LabelIcon from '@material-ui/icons/Label';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import logo from '../images/logo.png';

const HomepageLink = styled(Link)`
  color: white;
  font-size: 1.5rem;
  text-shadow: none;
  flex-grow: 1;
  text-decoration: none;
  background-image: none;
`;

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    image: {
      marginLeft: '8px',
      marginTop: '32px',
      height: '80px',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: colors.brand,
    },
    root: {
      width: '100%',
      maxWidth: 360,
    },
    listWrap: {
      '&:focus': {
        backgroundColor: '#43a6a8',
      },
    },
    listItemIcon: {
      color: 'white',
    },
    listItemText: {
      color: 'white',
      fontSize: '0.8em',
    },
    nested: {
      fontSize: '0.95em',
      paddingLeft: theme.spacing(4),
    },
    focused: {
      backgroundColor: '#00aeae',
    },
  }),
);

interface SideDrawerProps {
  mobileOpen: boolean;
  title: string;
  handleDrawerToggle: () => void;
}

interface PSGProps {
  name: string;
  id: string;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ mobileOpen, handleDrawerToggle, title }) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [psg, setPsg] = React.useState<PSGProps[]>([]);

  React.useEffect(() => {
    async function getFacebookGroups() {
      const groups = await firestore.collection('groups').get();

      const psgs: PSGProps[] = [];
      groups?.docs.forEach(group => {
        psgs.push({ name: group.data().name, id: group.id });
      });
      setPsg(psgs);
      setOpen(true);
    }

    if (firebase.auth().currentUser) getFacebookGroups();
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const drawer = (
    <div>
      <HomepageLink to="/">
        <img className={classes.image} src={logo} alt={title} />
      </HomepageLink>

      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListItem
          selected={useLocation().pathname === '/dashboard'}
          button
          onClick={() => navigate('/dashboard')}
          className={classes.listWrap}>
          <ListItemIcon className={classes.listItemIcon}>
            <PieChartIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" classes={{ primary: classes.listItemText }} />
        </ListItem>

        <ListItem
          selected={useLocation().pathname === '/user'}
          button
          onClick={() => navigate('/user')}
          className={classes.listWrap}>
          <ListItemIcon className={classes.listItemIcon}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="User Management" classes={{ primary: classes.listItemText }} />
        </ListItem>

        <ListItem
          selected={useLocation().pathname === '/tag'}
          button
          onClick={() => navigate('/tag')}
          className={classes.listWrap}>
          <ListItemIcon className={classes.listItemIcon}>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary="Facebook Tagging" classes={{ primary: classes.listItemText }} />
        </ListItem>

        <ListItem button onClick={handleClick}>
          <ListItemIcon className={classes.listItemIcon}>
            <FacebookIcon />
          </ListItemIcon>
          <ListItemText primary="PSG Management" classes={{ primary: classes.listItemText }} />
          {open ? <ExpandLess style={{ fill: 'white' }} /> : <ExpandMore style={{ fill: 'white' }} />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {psg.map(group => {
              return (
                <ListItem
                  key={group.id}
                  button
                  onClick={() => {
                    navigate('/psg');
                    localStorage.setItem('groupId', group.id);
                  }}
                  className={clsx(classes.nested, classes.listWrap, {
                    [classes.focused]:
                      localStorage.getItem('groupId') === group.id && window.location.pathname === '/psg',
                  })}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary={group.name} classes={{ primary: classes.listItemText }} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideDrawer;
