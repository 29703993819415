import * as React from 'react';
import { Link, navigate } from 'gatsby';
import { checkIsClient } from '../../utils';

export interface PrivateRouteProps {
  path?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path = '/' }) => {
  const isClient = React.useMemo(() => checkIsClient(), []);

  if (!isClient) return <Link to="/" />;

  const currentUser = typeof localStorage !== 'undefined' ? localStorage.getItem('currentUser') : null;

  if (currentUser && isClient && window.location.href !== path) return <>{children}</>;
  else {
    navigate('/');
    return <Link to="/" />;
  }
};

export default PrivateRoute;
